(function(){
    'use strict';

    angular.module('adminApp')
        .controller("AdminDashboardController", AdminDashboardController);

    AdminDashboardController.$inject = ["$scope", "$state", "$http", "$rootScope", "aerosAdminApi",
        "viewAdminUserModal", "Notification", "messageService", "allPermissions", "rbacService", "CommonService",
        "routesConstant", "rolesConstant", "aflPageInstructionsModal", "SessionService"];

    function AdminDashboardController($scope, $state, $http, $rootScope, aerosAdminApi, viewAdminUserModal,
                                      Notification, messageService, allPermissions, rbacService, CommonService,
                                      routesConstant, rolesConstant, aflPageInstructionsModal, SessionService) {


        var ADMIN = $scope.ADMIN = routesConstant.ADMIN;
        $scope.LOGIN = routesConstant.LOGIN;
        $scope.CUSTOMER = routesConstant.CUSTOMER;
        $scope.MFG = routesConstant.MFG;
        $scope.rolesConstant = rolesConstant;

        angular.extend($scope, {
            showInstructions: showInstructions
        });

        $rootScope.reject = function () {

            //check page we are on - if it is allowed then return

            if ($scope.allow['editOrganizations']){
                $state.go(ADMIN.ORGANIZATIONS.stateName);
            }else if($scope.allow['viewRogueSoftware']){
                $state.go(ADMIN.SOFTWARE.stateName);
            }else if($scope.allow['editAdminUsers']){
                $state.go(ADMIN.USERS.stateName);
            }else if($scope.allow['editSystemProperties']){
                $state.go(ADMIN.PROPERTIES.stateName);
            }else if($scope.allow['editSystemMessages']){
                $state.go(ADMIN.MESSAGES.stateName);
            }else if ($scope.allow['editSystemLock']){
                $state.go(ADMIN.LOCK.stateName);
            }else{
                $state.go(ADMIN.DASHBOARD.stateName);
            }

        };
        /* Jim
         * The reject will force them to the "first" allowed dashboard based on roles. This is ok, usually.
         *
         * However, the dashboard.software on upload now uploads to GCS which has a callback RogueSoftwareAdminController.java#handleUpload()
         * This sends a redirect which GCS retuns to the browser to /admin/#/dashboard/software
         * However, it reloads the dashboard.js file and the timing is off between going to the tab and getting the user info.
         *
         * GetUser is called to get Roles. However, we "go" to software dashboard before roles are back - ending up on the first active tab.
         *
         * (validateState is my attempt to not always force to the first available tab.. but I failed)
         *
         *
         * */

        //$scope.allow = {};
        $scope.holdState = $state.current.name;
        //console.log("holdState: "+$scope.holdState);

        $rootScope.validateState = function () {

            //check page we are on - if it is allowed then return
            // console.log("validateState - current hold state");
//		console.log($scope.holdState);

            // && $scope.allow['viewRogueSoftware']
            if ($scope.holdState && $scope.holdState!== ADMIN.DASHBOARD.stateName){
                // console.log("going to ["+$scope.holdState+"]");
                $state.go($scope.holdState);
                return;
            }

            $scope.holdState="";
            $scope.reject();

        };


        function showInstructions () {
            aflPageInstructionsModal.open($rootScope.appInfo.aerosUserGuideUrl);
        }

        $scope.$parent.page.title = "Administrator Dashboard";


        getUser();

        function getUser() {

            aerosAdminApi.getAdminUserProfile()
                .success(function (data) {
                    $scope.me = data;
                    $scope.userFullName = data.user.fullName;
                    $scope.email = data.user.email;

                    //Set up Role Access here
                    rbacService.getScope($scope);
                    rbacService.setAllPermissions(allPermissions);
                    $scope.allow = {};
                    $scope.allowMfg = {};
                    _.each(_.keys(allPermissions.adminPermissions), function (permission) {
                        $scope.allow[permission] = rbacService.checkAdminAccess(permission);
                    });
                    _.each(_.keys(allPermissions.adminPermissions), function (permission) {
                        $scope.allowMfg[permission] = rbacService.checkMfgAccess(permission);
                    });

                    $scope.validateState();  //forces to proper starting tab
                    $scope.commonReady = true;

                    // These settings are related to the portal switching options in the user menu.
                    // They define what portals the user has access to and the current portal the user is on.
                    $scope.hasAdminPortalRole = $scope.hasMfgPortalRole = $scope.hasCustomerPortalRole = false;
                    var appAccess = SessionService.getAppAccess();
                    if (appAccess) {
                        _.each(appAccess, function (access) {
                            if (access === rolesConstant.ADMIN) {
                                $scope.hasAdminPortalRole = true;
                            } else if (access === rolesConstant.MFG) {
                                $scope.hasMfgPortalRole = true;
                            } else if (access === rolesConstant.CUSTOMER) {
                                $scope.hasCustomerPortalRole = true;
                            }
                        })
                    }
                    $scope.role = rolesConstant.ADMIN;

                })
                .error(function (err) {
                    Notification.error("Something went wrong. " + err.message);
                });

        }

        $scope.showProfile = function() {
            viewAdminUserModal.open($scope.me).then(function () {
                getUser();
            });
        };

        $scope.changePassword = function (username) {
            CommonService.changePassword(username)
                .then(function (rtn) {
                    if (rtn.data.token) {
                        window.location.href = CommonService.baseUrl + "/password/admin/"
                            + encodeURIComponent(username) + "/" + rtn.data.token;
                    } else {
                        $state.go(LOGIN.LOGOUT.stateName, {role: rolesConstant.ADMIN});
                    }
                });
        };

        messageService.getMessages().then(function (config) {
            var activeMessages = config.getMessagesByTarget('Admin');
            var now = new Date().getTime();
            _.each(activeMessages, function (msg) {
                var start = new Date(msg.startDt).getTime();
                var end = new Date(msg.endDt).getTime();
                if (start < now && now < end) {
                    Notification.success({
                        message: msg.message,
                        delay: false
                    });
                }
            });
        });
    }

})();
